import { styled } from '@mui/material/styles'

export const StyledGridOfProductsContainer = styled('ul', {
  name: 'GridOfProducts',
  slot: 'Container',
})(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: theme.spacing(9, 8),
  margin: 0,
  padding: 0,

  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing(21, 16),
  },

  ['.MuiLink-underlineHover']: {
    ':hover': {
      textDecoration: 'none',
    },
  },

  li: {
    listStyle: 'none',
  },
}))
